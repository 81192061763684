import React from 'react';
import ResponsiveSlider from '../responsive-slider/responsive-slider';
import styles from './travel-nursing-slider.module.scss';

export default () => {
  const slides = [
    {
      title: 'Take control of your future',
      description:
        'Travel nursing allows you to visit new places, learn new skills, and meet new people—all while making a great living. Where do you want to go next?',
      ctaButtonText: 'Search jobs',
      ctaButtonLink: '/jobs/',
      iconClass: styles.slideIconBenefits,
    },
    {
      title: 'Get moving… fast',
      description:
        'More than half the candidates who complete their Trustaff profile get a job offer within 15 days. Don’t let your dream job pass you by!',
      ctaButtonText: 'Get started',
      ctaButtonLink: '/apply/',
      iconClass: styles.slideIconHeartbeat,
    },
    {
      title: 'Service that shines',
      description:
        'Finding a great recruiter is about more than just crunching numbers. You need a partner that will go to bat to ensure you’re treated right.',
      ctaButtonText: 'About us',
      ctaButtonLink: '/about-us/',
      iconClass: styles.slideIconCaring,
    },
  ];

  return <ResponsiveSlider slides={slides} />;
};
