import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import styles from './travel-nursing-section-4.module.scss';

export default ({ topFluid, bottomFluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h2 className="title is-3 has-text-info">
            Your salary and benefits package
          </h2>
          <div className="content">
            <p className={`content`}>
              Get the{' '}
              <Link to="/travel-nursing/pay-bonuses/">competitive pay</Link> you
              deserve with Trustaff. Our travel nurses earn{' '}
              <strong>20% to 30% more</strong> than the average staff nurse,
              making them among the best-paid healthcare professionals of their
              kind. While pay varies based on location, experience, and
              assignment, our nurses can earn more than $2,800 per week.
            </p>
            <p className={`content`}>
              Your recruiter will negotiate the most substantial compensation
              package with the hospitals and other healthcare facilities. That
              includes guaranteed hours, exclusive benefits, and commensurate
              pay. Your recruiter will also ensure that you have the flexibility
              you need to balance assignments with your personal life.
            </p>
            <p className={`content `}>
              Trustaff offers various perks and traveler benefits for travel
              nurses. For instance, we provide an expense reimbursement plan for
              any travel nurses that qualify under IRS guidelines. The program
              lets you earn tax-free pay on your assignments.
            </p>
            <p className={`content `}>
              All of our nurses are also eligible for{' '}
              <Link to="/nurses/salary-benefits/">medical benefits</Link>,
              starting as low as $24 a month. Options include medical, dental,
              vision, and short- and long-term disability insurance. We even
              have a 401k plan with an employer match, so you can start saving
              for your future.
            </p>
            <p className={`content `}>
              Our nurses are on the road for at least part of the year. At
              Trustaff, we bring the comforts of home to you, thanks to a wide
              range of discounts at your favorite stores. Travel nurses can now
              enjoy exclusive low prices at Planet Fitness, Roadtrippers, Walt
              Disney World, Avis, Wyndham Hotels, and Med Couture Scrubs. Check
              out our{' '}
              <Link to="/perks-discounts/">
                traveler perks and discounts
              </Link>{' '}
              page to see our complete lineup of offers.
            </p>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={topFluid}
            className={styles.aboutImageWrapper}
            alt="travel nursing locations"
          />
          <Img
            fluid={bottomFluid}
            className={`is-hidden-mobile ${styles.aboutImageWrapper} ${styles.img2}`}
            alt="travel nursing assignments"
          />
        </div>
      </div>
    </div>
  );
};
