import React from 'react';
import { Link } from 'gatsby';
import styles from './travel-nursing-closing-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h2 className="title is-3 has-text-info">Take the next step</h2>
          <div className="content">
            <p className={`content`}>
              Join the thousands of people already enjoying{' '}
              <a href="/travel-nursing-jobs/">travel nursing jobs</a> with
              Trustaff. Search our selection of jobs that fit your specialty and
              skills. When you are ready to take the next step,{' '}
              <Link to="/apply/">complete our application</Link> form to get
              started. One of our career specialists will contact you shortly.
            </p>
            <p className="content">
              When healthcare professionals want the best jobs and pay, they
              contact Trustaff. Our proven track record and strong industry
              relationships ensure that you'll have travel nurse assignments in
              no time. Give us a call at (877) 880-0346 or click below to get
              started today.
            </p>
          </div>
          <Link to="/apply/" className="button is-info">
            Get started
          </Link>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile} ${styles.testimonial}`}
        >
          <p className={`content ${styles.testimonialtext}`}>
            “I’ve done multiple assignments with different companies. The most
            important thing is having an honest, assertive, compliant nursing
            recruiter.
          </p>
          <p className={`content ${styles.testimonialtext}`}>
            My team at Trustaff is fabulous. Keep up the good work!”
          </p>
          <p className="content">— Cynthia</p>
        </div>
      </div>
    </div>
  );
};
