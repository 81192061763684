import React from 'react';
import { Link } from 'gatsby';
import styles from './travel-nursing-section-3.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h2 className="title is-3 has-text-info">Make a difference</h2>
          <div className="content">
            <p className={`content`}>
              Travel nurses have a direct impact on a patient's quality of care.
              They advocate for patient's rights, ensuring they get the optimal
              treatment. Travel nurses also provide critical support for the
              local staff and community when and where they need it most.
            </p>
            <p className="content">
              The best nurses soothe patients if they're nervous, anxious, or
              afraid. They leverage their training and experience to provide
              compassionate care on each assignment. If you have ever wondered,
              "Do travel nurses make a difference?" The answer is a resounding
              yes.
            </p>
            <p className="content">
              <strong>
                Here's a glimpse of some of the assignments our nurses have
                recently filled:
              </strong>
            </p>
            <ul>
              <li>Operating Room RN</li>
              <li>Emergency RN</li>
              <li>Unit Manager</li>
              <li>Labor and Delivery RN</li>
              <li>Cath Lab RN</li>
              <li>Medical-Surgical Registered Nurse</li>
            </ul>
            <p className="content">
              Specialty after specialty, we help qualified nurses find the jobs
              they need to grow a fulfilling nursing career. Our extensive
              network of connections means you'll have your choice of
              assignments suited to your specialty and preferences. Get the
              nursing experience you want and a pay rate you love by contacting
              a recruiter today.
            </p>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile} ${styles.testimonial}`}
        >
          <h2 className={`title is-5 ${styles.boxtitle}`}>COVID-19 Demand</h2>
          <p className="content">
            Travel nurses are more important than ever during the COVID-19
            pandemic. Healthcare clinics across the U.S. have high demand for
            experienced nurses as the virus continues to spread in urban and
            rural communities. Our travel nurses are on the front lines, helping
            fight back against this global crisis.
          </p>
          <p className="content">
            COVID-19 hotspots are changing frequently. Our recruiting team
            coordinates with healthcare facilities to fill their staffing needs
            while finding qualified staff nurses to take on the positions. Our
            immediate response to the COVID-19 demand has made Trustaff one of
            the leading choices among travel nurse agencies.
          </p>
          <Link to="/covid19/" className={`button is-primary`}>
            Learn more
          </Link>
        </div>
      </div>
    </div>
  );
};
