import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import styles from './index.module.scss';
import HeroImage from '../../components/hero-image/hero-image';
import HeroImageMini from '../../components/hero-image/hero-image-mini';
import TravelNursingSlider from '../../components/travel-nursing/travel-nursing-slider';
import TravelNursingSection1 from '../../components/travel-nursing/travel-nursing-section-1';
import TravelNursingSection2 from '../../components/travel-nursing/travel-nursing-section-2';
import TravelNursingSection4 from '../../components/travel-nursing/travel-nursing-section-4';
import TravelNursingSection3 from '../../components/travel-nursing/travel-nursing-section-3';
import TravelNursingClosingSection from '../../components/travel-nursing/travel-nursing-closing-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getTravelNursingImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const ogImageUrlPath = getTravelNursingImageEdge('travel-nursing-og-image')
    .node.childImageSharp.fluid.src;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;
  return (
    <>
      <Helmet>
        <title>Travel nursing - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="As the country’s top privately owned travel nursing agency, Trustaff's travelers are some of the highest-paid in the industry. You deserve the best of both worlds: great pay, comprehensive employee benefits, and outstanding service."
        />
        <meta
          property="og:title"
          content="Your travel nursing adventure starts here"
        />
        <meta
          property="og:description"
          content="As the country’s top privately owned travel nursing agency, Trustaff's travelers are some of the highest-paid in the industry. You deserve the best of both worlds: great pay, comprehensive employee benefits, and outstanding service."
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/travel-nursing/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage
          fluid={
            getTravelNursingImageEdge('travel-nursing-hero').node
              .childImageSharp.fluid
          }
        >
          <div className={'columns'}>
            <div className="column is-8 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-2 has-text-white`}>Travel nursing</h1>
                <p className={`content`}>
                  Trustaff has connected talented travel nurses with well-paying
                  jobs for more than two decades. We work with hospitals,
                  clinics, and healthcare centers across the country to find
                  jobs that are the right fit for you skills and your lifestyle.
                  No matter what your area of specialty, our recruiters have a
                  position for you.
                </p>
                <p className={`content`}>
                  Enjoy the best aspects of travel nursing when you sign up with
                  Trustaff. You'll get to travel everywhere from downtown
                  Manhattan to beach-side hospitals in Los Angeles, all while
                  earning a competitive salary. Join us today—and get your first
                  travel nursing assignment tomorrow.
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <TravelNursingSection1
            topFluid={
              getTravelNursingImageEdge('travel-nursing-sidebar-1sq').node
                .childImageSharp.fluid
            }
            bottomFluid={
              getTravelNursingImageEdge('travel-nursing-sidebar-4').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div className={`section ${styles.sectionBackgroundGrey}`}>
          <TravelNursingSlider />
        </div>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <TravelNursingSection2
            topFluid={
              getTravelNursingImageEdge('travel-nursing-sidebar-2').node
                .childImageSharp.fluid
            }
            bottomFluid={
              getTravelNursingImageEdge('travel-nursing-sidebar-3').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <TravelNursingSection3 />
        </div>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <TravelNursingSection4
            topFluid={
              getTravelNursingImageEdge('travel-nursing-sidebar-5').node
                .childImageSharp.fluid
            }
            bottomFluid={
              getTravelNursingImageEdge('travel-nursing-sidebar-6').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <TravelNursingClosingSection />
        </div>
        <HeroImageMini
          fluid={
            getTravelNursingImageEdge('travel-nursing-big-beach').node
              .childImageSharp.fluid
          }
        ></HeroImageMini>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^travel-nursing/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
