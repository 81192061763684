import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import styles from './travel-nursing-section-2.module.scss';

export default ({ topFluid, bottomFluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h2 className="title is-3 has-text-info">Becoming a travel nurse</h2>
          <div className="content">
            <p className={`content`}>
              Travel nursing involves working temporary positions for anywhere
              from 8-26 weeks at a time, though most assignments are 13 weeks.
              Each travel nurse has a say as to where they want to work and
              their desired specialty. Agencies coordinate with clinics,
              hospitals, and other facilities to fill the demand for their
              services nationwide.
            </p>
            <p className={`content`}>
              Most of our travelers are either Registered Nurses (RNs), Nurse
              Practitioners (NPs), or Allied healthcare professionals. They
              handle multiple functions such as running diagnostic tests,
              creating treatment plans, and providing hands-on patient care.
            </p>
            <p className={`content ${styles.subheader}`}>
              Agencies can work with a travel nurse as soon as they complete the
              necessary educational and experience requirements. In addition to
              a high school diploma or GED, a travel nurse must also have:
            </p>
            <ul className={`is-size-7`}>
              <li>
                Completed a Licensed Practical Nurse (LPN) program, Associate
                Degree in Nursing (ADN) program, or Bachelor of Science in
                Nursing (BSN) program
              </li>
              <li>Passed the NCLEX exam</li>
              <li>A valid RN or LPN/LVN license in your home state</li>
              <li>
                At least one year of direct experience in your area of specialty
              </li>
              <li>
                Decided on your desired travel goals, including location and
                assignment lengths
              </li>
              <li>Updated your resume for travel nursing</li>
            </ul>
            <Link to="/apply/" className="button is-primary">
              Get started
            </Link>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={topFluid}
            className={styles.aboutImageWrapper}
            alt="travel nursing locations"
          />
          <Img
            fluid={bottomFluid}
            className={`is-hidden-mobile ${styles.aboutImageWrapper} ${styles.img2}`}
            alt="travel nursing assignments"
          />
        </div>
      </div>
    </div>
  );
};
