import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './travel-nursing-section-1.module.scss';

export default ({ topFluid, bottomFluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h2 className="title is-3 has-text-info">Why choose Trustaff</h2>
          <div className="content">
            <p className="content">
              At Trustaff, we see you as more than just a body filling a
              role—you're a vibrant individual with dreams and aspirations. As
              one of the nation's leading travel nursing companies, your
              recruiter will work with you one-on-one to guide your career and
              help you achieve your goals.
            </p>
            <p className="content">
              Our recruiters understand the time and commitment that travel
              nursing requires. We know that travel nursing lets you fulfill
              career goals while maintaining your flexibility and freedom. We
              put you in the driver's seat so you never have to compromise your
              work or pay.
            </p>
            <h3 className="title is-5 has-text-info">
              Our nurse-first attitude has set us apart from other travel
              nursing agencies
            </h3>
            <p>
              We are proud to have one of the highest nurse retention rates in
              the country. Some of the other reasons people choose us include:
            </p>
            <ul className={`is-size-7`}>
              <li>
                Priority access to thousands of{' '}
                <Link to="/jobs/">travel nursing jobs</Link>
              </li>
              <li>Industry-leading pay</li>
              <li>Guaranteed weekly hours</li>
              <li>Experienced recruiters</li>
              <li>
                Comprehensive{' '}
                <Link to="/nurses/salary-benefits/">benefits</Link>, including
                medical, dental, and vision
              </li>
              <li>401k with employer match</li>
              <li>Assistance with travel, compliance, and housing</li>
              <li>
                <Link to="/travel-nursing/license-reimbursement/">
                  License reimbursement
                </Link>
              </li>
              <li>
                Career tools, professional advocacy, and wellness resources
                through the Ingenovis Health{' '}
                <Link to="/ACTprogram/">ACT program</Link>{' '}
              </li>
              <li>
                <Link to="/travel-nursing/pay-bonuses/">Great bonuses</Link> for
                completions, <Link to="/referrals/">referring friends</Link>,
                and more
              </li>
            </ul>
            <p className="content">
              Get access to exclusive jobs for all nursing specialties. Our{' '}
              <Link to="/travel-nursing/license-reimbursement/">
                nursing licensure program
              </Link>{' '}
              can help you obtain a new state license and work wherever you
              want. We cover miscellaneous expenses, including your housing
              stipend, and never charge unscrupulous fees.
            </p>
          </div>
          <Link to="/apply/" className="button is-primary">
            Get started
          </Link>
        </div>
        <div className={`column is-5 is-offset-1 is-hidden-mobile`}>
          <Img
            fluid={topFluid}
            className={styles.aboutImageWrapper}
            alt="travel nursing"
          />
          <Img
            fluid={bottomFluid}
            className={`is-hidden-mobile ${styles.aboutImageWrapper} ${styles.img2}`}
            alt="travel nursing jobs"
          />
        </div>
      </div>
    </div>
  );
};
